import { ICoreContext } from '@msdyn365-commerce/core'
import {
    Cart,
    CartLine,
    Customer,
    SalesLine,
    SalesOrder,
    SimpleProduct,
} from '@msdyn365-commerce/retail-proxy'

import {
    saveCurrentCategoryHierarchyAndListName,
    getPageType,
    extractCategoryNames,
    getCurrentCategoryHierarchyAndListName,
    getItemFromSimpleProduct,
    getListIdFromListName,
    getItemFromCartLine,
    getItemFromSalesLine,
    setOrderPlacedStatusLocalStorage,
    getOrderPlacedStatusLocalStorage,
    saveAddToCartCategoryHierarchyAndListName,
    refreshAddToCartCategoryHierarchyAndListName,
    hashSHA256,
    getCheckoutCartFromLocalStorage,
    setCheckoutCartInLocalStorage,
    getUserDataForTracking,
    getUserPrimaryAddress,
} from './helpers'

import {
    CheckoutType,
    GtmEvents,
    LoginFromType,
    LoginState,
    SessionStorageKey,
} from './types'

const eventQueue: any[] = []
let isUserDataTracked = false
let userEventDataGlobal: any

const triggerDLEvent = (DLObject: any) => {
    if (isUserDataTracked) {
        const {
            shouldTrackShippingAddress,
            shippingAddress,
            ...correctDLObject
        } = DLObject

        window.dataLayer?.push({ ecommerce: null })
        window.dataLayer?.push?.({
            ...correctDLObject,
            user_data: getUserDataForTracking(userEventDataGlobal.user_data, DLObject),
            user_properties: userEventDataGlobal.user_properties,
            global_variables: userEventDataGlobal.global_variables
        })

        return
    }

    eventQueue.push(DLObject)
}

export const trackUserData = (
    context: ICoreContext,
    customerData: Customer | undefined,
    hasLoyaltyCard: boolean
) => {
    const userData = context.request.user

    if (!userData) return

    if (userData.isSignedIn && !customerData) return

    const userEventData = getUserEventData(context, customerData, hasLoyaltyCard)

    if (!userEventData) return

    window.dataLayer?.push?.(userEventData)
    userEventDataGlobal = userEventData
    isUserDataTracked = true

    if (eventQueue.length) {
        eventQueue.forEach(DLObjectEach => {
            const {
                shouldTrackShippingAddress,
                shippingAddress,
                ...correctDLObject
            } = DLObjectEach

            window.dataLayer?.push({ ecommerce: null })
            window.dataLayer?.push?.({
                ...correctDLObject,
                user_data: getUserDataForTracking(userEventDataGlobal.user_data, DLObjectEach),
                user_properties: userEventDataGlobal.user_properties,
                global_variables: userEventDataGlobal.global_variables
            })
        })
    }
}

const getUserEventData = (
    context: ICoreContext,
    customerData: Customer | undefined,
    hasLoyaltyCard: boolean
) => {
    const userData = context.request.user

    const birthDate = customerData?.Attributes?.find(
        a => a.Name === 'Birthday'
    )?.AttributeValue?.StringValue || ''

    const hasGenericMarketing = customerData?.Attributes?.find(
        a => a.Name === 'GenericMarketing'
    )?.AttributeValue?.BooleanValue || false

    const formatDate = (date: Date): string => {
        const d = new Date(date)
    
        const year = d.getFullYear()

        if (year === 1900) return ''

        const month = String(d.getMonth() + 1).padStart(2, '0')
        const day = String(d.getDate()).padStart(2, '0')
    
        return `${year}-${month}-${day}`
    }

    return {
        event: GtmEvents.USER_DATA,
        ...(userData.isSignedIn && customerData ? {
            user_data: {
                email: customerData.Email,
                phone_number: customerData.Phone,
                address: {
                    first_name: customerData.FirstName,
                    last_name: customerData.LastName,
                    ...getUserPrimaryAddress(customerData.Addresses),
                }
            },
            user_properties: {
                user_id: customerData.AccountNumber,
                date_of_birth: /^\d{4}-\d{2}-\d{2}$/.test(birthDate) ? birthDate : '',
                gender: '',
                signup_date: customerData.CreatedDateTime ? formatDate(customerData.CreatedDateTime) : '',
                vclub: hasLoyaltyCard,
                newsletter_subscription: String(hasGenericMarketing),
                email_hashed: customerData.Email ? hashSHA256(customerData.Email) : '',
                phone_number_hashed: customerData.Phone ? hashSHA256(customerData.Phone) : '',
            }
        } : {
            user_data: {
                email: '',
                phone_number: '',
                address: {
                    first_name: '',
                    last_name: '',
                    street: '',
                    city: '',
                    region: '',
                    country: '',
                    postal_code: ''
                } 
            },
            user_properties: {
                user_id: '',
                date_of_birth: '',
                gender: '',
                signup_date: '',
                vclub: false,
                newsletter_subscription: '',
                email_hashed: '',
                phone_number_hashed: '', 
            }
        }),
        global_variables: {
            Page_type: getPageType(context),
            login: userData.isSignedIn ? 'logged-in' : 'not-logged-in'
        }
    }
}

// View Item List
export const trackViewItemList = (
    context: ICoreContext,
    products: SimpleProduct[],
    listName: string,
    categoryHierarchy: string
): void => {
    if (!products.length) return

    const categories = categoryHierarchy ? extractCategoryNames(categoryHierarchy) : [getPageType(context), '', '', '', '']

    const currency = context?.request?.channel?.Currency || ''
    // @ts-ignore
    const items = []
    products.forEach((product: SimpleProduct, index: number) => {
        items.push(
            getItemFromSimpleProduct(
                product,
                categories,
                listName,
                index,
                1,
                currency
            )
        )
    })

    triggerDLEvent({
        event: GtmEvents.VIEW_ITEM_LIST,
        item_list_id: getListIdFromListName(listName),
        item_list_name: listName,
        ecommerce: {
            currency,
            // @ts-ignore
            items
        },
    })
}

// View_item
export const trackViewItemFromPDP = (
    context: ICoreContext,
    product: SimpleProduct | undefined,
    price: number,
    originalPrice: number
): void => {
    if (!product) return

    const { categoryHierarchy, listName } = getCurrentCategoryHierarchyAndListName()

    const categories = categoryHierarchy ? categoryHierarchy : ['', '', '', '', '']

    const currency = context?.request?.channel?.Currency || ''

    triggerDLEvent({
        event: GtmEvents.VIEW_ITEM,
        ecommerce: {
            currency,
            // @ts-ignore
            value: Number(price.toFixed(2)),
            items: [
                getItemFromSimpleProduct(
                    {
                        ...product,
                        Price: price,
                        BasePrice: originalPrice
                    },
                    categories,
                    listName,
                    0,
                    1,
                    currency
                )
            ]
        },
    })
}

// Select Item in product tile
export const trackProductTileClick = (
    context: ICoreContext,
    product: SimpleProduct,
    categoryHierarchy: string,
    listName: string
): void => {
    saveCurrentCategoryHierarchyAndListName(context, categoryHierarchy, listName)
    const categories = categoryHierarchy ? extractCategoryNames(categoryHierarchy) : [getPageType(context), '', '', '', '']
    const currency = context?.request?.channel?.Currency || ''

    triggerDLEvent({
        event: GtmEvents.SELECT_ITEM,
        ecommerce: {
            item_list_id: getListIdFromListName(listName),
            item_list_name: listName,
            currency,
            value: Number(product.Price.toFixed(2)),
            items: [
                getItemFromSimpleProduct(
                    product,
                    categories,
                    listName,
                    0,
                    1,
                    currency
                )
            ]
        },
    })
}

// Add to cart from listing
export const trackAddToCartFromListing = (
    context: ICoreContext,
    product: SimpleProduct,
    categoryHierarchy: string,
    listName: string,
): void => {
    saveAddToCartCategoryHierarchyAndListName(context, categoryHierarchy, listName)
    const categories = categoryHierarchy ? extractCategoryNames(categoryHierarchy) : [getPageType(context), '', '', '', '']
    const currency = context?.request?.channel?.Currency || ''

    triggerDLEvent({
        event: GtmEvents.ADD_TO_CART,
        ecommerce: {
            currency,
            value: Number(product.Price.toFixed(2)),
            items: [
                getItemFromSimpleProduct(
                    product,
                    categories,
                    listName,
                    0,
                    1,
                    currency
                )
            ]
        },
    })
}

// Add to cart from PDP
export const trackAddToCartFromGWP = (
    context: ICoreContext,
    product: SimpleProduct | undefined,
    quantity: number
): void => {
    if (!product) return

    const categories = ['GWP','','','','']
    const listName ='GWP'

    const currency = context?.request?.channel?.Currency || ''

    triggerDLEvent({
        event: GtmEvents.ADD_TO_CART,
        ecommerce: {
            currency,
            value: Number(product.Price.toFixed(2)) * quantity,
            items: [
                getItemFromSimpleProduct(
                    product,
                    categories,
                    listName,
                    0,
                    quantity,
                    currency
                )
            ]
        },
    })
}

// Add to cart from PDP
export const trackAddToCartFromPDP = (
    context: ICoreContext,
    product: SimpleProduct | undefined,
    quantity: number,
    price: number,
    originalPrice: number,
): void => {
    if (!product) return

    const { categoryHierarchy, listName } = getCurrentCategoryHierarchyAndListName()
    refreshAddToCartCategoryHierarchyAndListName()
    var categories = categoryHierarchy ? categoryHierarchy : ['', '', '', '', '']
    const currency = context?.request?.channel?.Currency || ''

    triggerDLEvent({
        event: GtmEvents.ADD_TO_CART,
        ecommerce: {
            currency,
            value: Number(price.toFixed(2)) * quantity,
            items: [
                getItemFromSimpleProduct(
                    {
                        ...product,
                        Price: price,
                        BasePrice: originalPrice
                    },
                    categories,
                    listName,
                    0,
                    quantity,
                    currency
                )
            ]
        },
    })
}

// View Cart
export const trackViewCart = (
    context: ICoreContext,
    cart: Cart | undefined,
    products: SimpleProduct[]
): void => {
    if (!cart) return

    const currency = context?.request?.channel?.Currency || ''

    // @ts-ignore
    const items = []
    cart.CartLines?.forEach((cartLine: CartLine, index: number) => {
        const productName = products.find(p => p.ItemId === cartLine.ItemId)?.Name || cartLine.Description || ''

        items.push(
            getItemFromCartLine(
                cartLine,
                productName,
                cart.Coupons || [],
                index,
                currency
            )
        )
    })

    triggerDLEvent({
        event: GtmEvents.VIEW_CART,
        ecommerce: {
            currency,
            value: Number(cart.AmountDue?.toFixed(2) || 0),
            // @ts-ignore
            items
        },
    })
}

// Remove from cart
export const trackRemoveFromCart = (
    context: ICoreContext,
    cart: Cart | undefined,
    products: SimpleProduct[],
    cartLine: CartLine | undefined
): void => {
    if (!cart || !cartLine) return

    const productName = products.find(p => p.ItemId === cartLine.ItemId)?.Name || cartLine.Description || ''
    const currency = context?.request?.channel?.Currency || ''

    triggerDLEvent({
        event: GtmEvents.REMOVE_FROM_CART,
        ecommerce: {
            currency,
            // @ts-ignore
            value: context.actionContext.requestContext.telemetryData.activeCart.AmountDue.toFixed(2),
            items: [
                getItemFromCartLine(
                    cartLine,
                    productName,
                    cart.Coupons || [],
                    0,
                    currency
                )
            ]
        },
    })
}

// Begin Checkout
export const trackBeginCheckout = (
    context: ICoreContext,
    cart: Cart | undefined,
    products: SimpleProduct[],
    checkoutType: CheckoutType
): void => {
    if (!cart) return

    const currency = context?.request?.channel?.Currency || ''

    // @ts-ignore
    const items = []
    cart.CartLines?.forEach((cartLine: CartLine, index: number) => {
        const productName = products.find(p => p.ItemId === cartLine.ItemId)?.Name || cartLine.Description || ''

        items.push(
            getItemFromCartLine(
                cartLine,
                productName,
                cart.Coupons || [],
                index,
                currency
            )
        )
    })

    triggerDLEvent({
        event: GtmEvents.BEGIN_CHECKOUT,
        checkout_type: checkoutType,
        ecommerce: {
            currency,
            value: Number(cart.AmountDue?.toFixed(2) || 0),
            // @ts-ignore
            items
        },
    })
}

// Purchase
export const trackPurchase = (
    salesOrder: SalesOrder | undefined,
    products: SimpleProduct[]
): void => {
    const isOrderPlaced = getOrderPlacedStatusLocalStorage()
    if (!salesOrder || !products.length || !isOrderPlaced) return

    setOrderPlacedStatusLocalStorage(false)
    
    // @ts-ignore
    const items = []
    const checkoutPreviousCart = getCheckoutCartFromLocalStorage()
    setCheckoutCartInLocalStorage(undefined)
    const currency = salesOrder.CurrencyCode || ''

    if (checkoutPreviousCart &&
        checkoutPreviousCart.Id === salesOrder.Id &&
        checkoutPreviousCart.CartLines?.length === salesOrder.SalesLines?.length
    ) {
        checkoutPreviousCart.CartLines?.forEach((cartLine: CartLine, index: number) => {
            const productName = products.find(p => p.ItemId === cartLine.ItemId)?.Name || cartLine.Description || ''
            items.push(
                getItemFromCartLine(
                    cartLine,
                    productName,
                    checkoutPreviousCart.Coupons || [],
                    index,
                    currency
                )
            )
        })
    } else {
        salesOrder.SalesLines?.forEach((salesLine: SalesLine, index: number) => {
            const productName = products.find(p => p.ItemId === salesLine.ItemId)?.Name || salesLine.Description || ''

            items.push(
                getItemFromSalesLine(
                    salesLine,
                    productName,
                    index,
                    currency
                )
            )
        })
    }

    const couponCodes = salesOrder.SalesLines?.filter(salesLine => salesLine.DiscountLines && salesLine.DiscountLines.length).map(salesLine => {
        return salesLine.DiscountLines!.filter(d => d.EffectiveAmount && d.EffectiveAmount > 0.01 && d.DiscountCode).map(
            discountLine => discountLine.DiscountCode!
        )
    }).flat()

    const uniqueCouponCodes = [...new Set(couponCodes)]

    const joinedCouponCodes = uniqueCouponCodes.join('_')

    const shipping = salesOrder.IsTaxIncludedInPrice ?
        (salesOrder.ShippingChargeAmount || 0) + (salesOrder.TaxOnShippingCharge || 0) :
        salesOrder.ShippingChargeAmount || 0

    triggerDLEvent({
        event: GtmEvents.PURCHASE,
        ecommerce: {
            coupon: joinedCouponCodes,
            value: Number(salesOrder.SubtotalAmount?.toFixed(2) || 0),
            tax: Number(salesOrder.TaxAmount?.toFixed(2) || 0),
            shipping: Number(shipping.toFixed(2)),
            currency,
            transaction_id: salesOrder.Id,
            order_id: salesOrder.ChannelReferenceId,
            // @ts-ignore
            items
        },
        shouldTrackShippingAddress: !!salesOrder.SalesLines,
        shippingAddress: salesOrder.SalesLines ? {
            street: salesOrder.SalesLines[0]?.ShippingAddress?.Street || '',
            city: salesOrder.SalesLines[0]?.ShippingAddress?.City || '',
            region: salesOrder.SalesLines[0]?.ShippingAddress?.County || '',
            country: salesOrder.SalesLines[0]?.ShippingAddress?.ThreeLetterISORegionName || '',
            postal_code: salesOrder.SalesLines[0]?.ShippingAddress?.ZipCode || ''
        } : {
            street: '',
            city: '',
            region: '',
            country: '',
            postal_code: ''
        }
    })
}

// Login
export const trackLogin = (isUserSigned: boolean): void => {
    const prevLoginState = sessionStorage.getItem(SessionStorageKey.PREV_LOGIN_STATE)

    if (prevLoginState) {
        if (isUserSigned && prevLoginState === LoginState.GUEST) {
            const isCheckoutLogin = sessionStorage.getItem(SessionStorageKey.LOGIN_FROM) === LoginFromType.CHECKOUT

            triggerLoginEvent(isCheckoutLogin ? LoginFromType.CHECKOUT : LoginFromType.LOGIN)

            sessionStorage.setItem(SessionStorageKey.PREV_LOGIN_STATE, LoginState.LOGGED_USER)
        } else if (!isUserSigned && prevLoginState === LoginState.LOGGED_USER) {
            sessionStorage.setItem(SessionStorageKey.PREV_LOGIN_STATE, LoginState.GUEST)
        }
    } else {
        sessionStorage.setItem(SessionStorageKey.PREV_LOGIN_STATE, isUserSigned ? LoginState.LOGGED_USER : LoginState.GUEST)
    }

    sessionStorage.removeItem(SessionStorageKey.LOGIN_FROM)
}

const triggerLoginEvent = (loginFrom: LoginFromType) => {
    triggerDLEvent({
        event: GtmEvents.LOGIN,
        method: '',
        success: true,
        login_from: loginFrom
    })
}

// Add_shipping_info
export const trackAddShippingInfo = (
    context: ICoreContext,
    cart: Cart | undefined,
    products: SimpleProduct[]
): void => {
    if (!cart) return

    const currency = context?.request?.channel?.Currency || ''

    // @ts-ignore
    const items = []
    cart.CartLines?.forEach((cartLine: CartLine, index: number) => {
        const productName = products.find(p => p.ItemId === cartLine.ItemId)?.Name || cartLine.Description || ''

        items.push(
            getItemFromCartLine(
                cartLine,
                productName,
                cart.Coupons || [],
                index,
                currency
            )
        )
    })

    const couponCodesSet = cart.Coupons?.map(c => {
        return c.Code
    })

    const joinedCouponCodes = (couponCodesSet && couponCodesSet.length) ? couponCodesSet.join('_') : ''

    triggerDLEvent({
        event: GtmEvents.ADD_SHIPPING_INFO,
        ecommerce: {
            currency,
            coupon: joinedCouponCodes,
            shipping_tier: '',
            value: Number(cart.AmountDue?.toFixed(2) || 0),
            // @ts-ignore
            items
        },
    })
}

// Add_payment_info
export const trackAddPaymentInfo = (
    context: ICoreContext,
    cart: Cart | undefined,
    products: SimpleProduct[]
): void => {
    if (!cart) return

    const currency = context?.request?.channel?.Currency || ''

    // @ts-ignore
    const items = []
    cart.CartLines?.forEach((cartLine: CartLine, index: number) => {
        const productName = products.find(p => p.ItemId === cartLine.ItemId)?.Name || cartLine.Description || ''

        items.push(
            getItemFromCartLine(
                cartLine,
                productName,
                cart.Coupons || [],
                index,
                currency
            )
        )
    })

    const couponCodesSet = cart.Coupons?.map(c => {
        return c.Code
    })

    const joinedCouponCodes = (couponCodesSet && couponCodesSet.length) ? couponCodesSet.join('_') : ''

    triggerDLEvent({
        event: GtmEvents.ADD_PAYMENT_INFO,
        ecommerce: {
            currency,
            coupon: joinedCouponCodes,
            payment_type: '',
            value: Number(cart.AmountDue?.toFixed(2) || 0),
            // @ts-ignore
            items
        },
    })
}

// add_to_wishlist from PDP (Product Detail Page)
export const trackAddToWishlistFromPDP = (
    context: ICoreContext,
    product: SimpleProduct | undefined,
    price: number,
    originalPrice: number,
): void => {
    if (!product) return

    const userData = context.request.user

    if (!userData || !userData.isSignedIn) return

    const { categoryHierarchy, listName } = getCurrentCategoryHierarchyAndListName()
    refreshAddToCartCategoryHierarchyAndListName()
    var categories = categoryHierarchy ? categoryHierarchy : ['', '', '', '', '']
    const currency = context?.request?.channel?.Currency || ''

    triggerDLEvent({
        event: GtmEvents.ADD_TO_WISHLIST,
        ecommerce: {
            currency,
            value: Number(price.toFixed(2)),
            items: [
                getItemFromSimpleProduct(
                    {
                        ...product,
                        Price: price,
                        BasePrice: originalPrice
                    },
                    categories,
                    listName,
                    0,
                    1,
                    currency
                )
            ]
        },
    })
}

// add_to_wishlist from Cart Page
export const trackAddToWishlistFromCart = (
    context: ICoreContext,
    cart: Cart | undefined,
    products: SimpleProduct[],
    cartLine: CartLine | undefined
): void => {
    if (!cart || !cartLine) return

    const productName = products.find(p => p.ItemId === cartLine.ItemId)?.Name || cartLine.Description || ''
    const currency = context?.request?.channel?.Currency || ''

    triggerDLEvent({
        event: GtmEvents.ADD_TO_WISHLIST,
        ecommerce: {
            currency,
            // @ts-ignore
            value: context.actionContext.requestContext.telemetryData.activeCart.AmountDue.toFixed(2),
            items: [
                getItemFromCartLine(
                    cartLine,
                    productName,
                    cart.Coupons || [],
                    0,
                    currency
                )
            ]
        },
    })
}

// click_menu
export const trackClickMenu = (
    category: string,
    action: string,
    label: string
): void => {
    if (!category) return

    triggerDLEvent({
        event: GtmEvents.CLICK_MENU,
        category,
        action,
        label
    })
}

// click_filter
export const trackClickFilter = (
    category: string | undefined,
    label: string,
    priceFilter?: { min: number, max: number }
): void => {
    if (!category) return

    triggerDLEvent({
        event: GtmEvents.CLICK_FILTER,
        category,
        label,
        ...(priceFilter ? {price_min: priceFilter.min, price_max: priceFilter.max} : {})
    })
}

// view_search_results
export const trackSearch = (
    searchText: string
): void => {
    triggerDLEvent({
        event: GtmEvents.VIEW_SEARCH_RESULT,
        search_term: searchText
    })
}

// my_venchi_mix
export const trackAddVenchiMix = (
    products: {
        category: string,
        action: number,
        label: number
    }[]
): void => {
    triggerDLEvent({
        event: GtmEvents.ADD_VENCHI_MIX,
        products,
    })
}

// vclub_signup
export const trackVClubRegistration = (): void => {
    window.dataLayer?.push?.({
        event: GtmEvents.VCLUB_SIGNUP
    })
};
